import axios from 'axios'

import JwtService from '@/core/services/jwt.service'
import VueAxios from "vue-axios";
import Vue from 'vue'

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init (app) {
    app.use(VueAxios, axios)
    app.config.globalProperties.axios = axios
    app.axios.defaults.baseURL = process.env.VUE_APP_API_URL
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader () {},

  query (resource, params) {
    return axios.get(resource, { params }).catch(error => {
      throw new Error(`[KT] ApiService ${error}`)
    })
  },

  get (resource, params = {}) {
    return axios
        .get(resource, { params })
        .then(response => {
          return response
        }).catch(err => {
          throw new Error(`[KT] ApiService ${err}`)
        })
  },

  getImage (resource, config) {
    return axios
        .get(resource, config)
        .then(response => {
          return response
        }).catch(err => {
          throw new Error(`[KT] ApiService ${err}`)
        })
  },

  search(resource, params) {
    return axios
        .get(resource, { params }) // Inclui os parâmetros na requisição
        .then(response => {
          return response; // Retorna apenas os dados da resposta
        })
        .catch(error => {
          if (error.response) {
            throw new Error(`${error.response.data.message}`);
          } else {
            // Lidar com erros que não têm resposta (e.g., problemas de rede)
            throw new Error('Erro ao fazer a requisição');
          }
        });
  },

  post (resource, params) {
    return axios.post(resource, params, {
      headers: {}
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      if (error.response) {
        throw new Error(`${error.response.data.message}`);
      }
    });
  },

  put (resource, params) {
    return axios.put(resource, params, {})
        .then(response => {
          return response;
        })
        .catch(error => {
          if (error.response) {
            throw new Error(`${error.response.data.message}`);
          }
        });
  },

  postForm (resource, formData, isUpdate = false){
    if (isUpdate) {
      formData.append('_method', 'PUT')
    }

    return axios.post(resource, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      if (error.response) {
        throw new Error(`${error.response.data.message}`);
      }
    });
  },

  update (resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params)
  },

  /*put (resource, params) {
    return axios.put(`${resource}`, params)
  },*/

  delete (resource) {
    return axios
      .delete(resource)
      .then(response => {
        return response;
      })
      .catch(error => {
        if (error.response) {
          throw new Error(`${error.response.data.message}`);
        } else {
          // Lidar com erros que não têm resposta (e.g., problemas de rede)
          throw new Error('Erro ao fazer a requisição para excluir o registro');
        }
      });
  }
}

export default ApiService
