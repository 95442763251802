import ApiService from '@/core/services/api.service'
import {showSuccessMessage} from "@/core/utils/alerts/sucess";

const state = {
    insertBookData: {}, // Estado onde os dados serão salvos
}

const getters = {
    insertBookData (state) {
        return state.insertBookData
    },
}

// ações
const actions = {

    insertNewBook(context, params)
    {
        return ApiService.postForm('/books', params).then(response =>
        {
            if (response.status === 201)
            {
                showSuccessMessage('Sucesso!', 'Livro adicionado!');
                return response.data.id;
            }

            return false

        })
        .catch(error => {
            return false
        });
    },

}

const mutations = {
    setInsertBookData(state, data) {
        state.insertBookData = data;
    },
    resetInsertBookData(state) {
        state.insertBookData = {};
    }
}

export default {
  state,
  actions,
  mutations,
  getters
}
