import ApiService from '@/core/services/api.service'
import {showSuccessMessage} from "@/core/utils/alerts/sucess";

const state = {
}

// ações
const actions = {

    fetchBooks(context, params)
    {
        return ApiService.post("/books/search", params).then(response =>
        {
            //console.log(response.data)
            return response.data
        })
        .catch(error => {
            console.log('Erro ao pesquisar livros', error);
            return false
        });
    },
}
const getters = {}
const mutations = {}

export default {
  state,
  actions,
  mutations,
  getters
}
