import ApiService from '@/core/services/api.service'
import { showSuccessMessage } from '@/core/utils/alerts/sucess';


const state = {
    errors: null,
    dataTour: {},
    editCurrentTour: {}
}

const getters = {
    newTour (state) {
        return state.dataTour
    },
    editTour (state) {
        return state.editCurrentTour
    },
}

// ações
const actions = {

    selectTypeTour(context, id) {
        context.commit('setTypeTour', id)
    },

    insertNewTour(context, param){

        return ApiService.postForm('/tour', param).then(response =>
        {
            if (response.status === 201)
            {
                context.dispatch('clearDataTour');
                return response.data.id;
            }

            return false

        })
        .catch(error => {
            return false
        });
    },

    updateTour(context, params){

        return ApiService.post(`/tour/${params.id}`, params.payload).then(response =>
        {
            return response.status === 201;
        }).catch(error => {
            return false
        });
    },

    updateImageTour(context, { id, formData}){

        return ApiService.post(`/tour/${id}/image`, formData).then(response =>
        {
            return response.status === 201;
        }).catch(error => {
            return false
        });
    },

    clearDataTour(context) {
        context.commit('clear')
    },
}

const mutations = {
    setTypeTour(state, data) {
        state.dataTour.type = data
    },
    clear(state) {
        state.dataTour = {}
    },

    updateCurrentTourTitle(state, newTitle) {
        state.editCurrentTour.title = newTitle;
    },
    updateCurrentTourQuantity(state, newQuantity) {
        state.editCurrentTour.quantity = newQuantity;
    },
    updateCurrentTourDescription(state, newDescription) {
        state.editCurrentTour.description = newDescription;
    },
    setEditTour(state, dataTour) {
        state.editCurrentTour = dataTour;
    },


}

export default {
  state,
  actions,
  mutations,
  getters
}
