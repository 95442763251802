import { createStore } from 'vuex'
import auth from '@/core/stores/modules/auth.module'
import users from '@/core/stores/modules/users.module'
import tourCreate from '@/core/stores/modules/tour.create.module'
import tours from '@/core/stores/modules/tours.module'
import tour_functions from '@/core/stores/modules/tour.functions.module'

import topics from '@/core/stores/modules/topics.module'
import item from '@/core/stores/modules/tour.item.module'
import item_user from '@/core/stores/modules/tour.item.user.module'
import feeds from '@/core/stores/modules/feeds.module'
import comments from '@/core/stores/modules/comments.module'
import books_functions from '@/core/stores/modules/books.functions.module'
import books_search from '@/core/stores/modules/books.search.module'

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
  modules: {
    auth,
    users,
    tourCreate,
    tours,
    tour_functions,
    topics,
    item,
    item_user,
    feeds,
    comments,
    books_functions,
    books_search
  },
  state: {
    isLoading: false,
  },
  mutations:
  {
    setLoading(state, isLoading) {
      //console.log('Loading: '+isLoading)
      state.isLoading = isLoading;
    },
  },
  actions: {},
  getters: {
    isLoading (state) {
      return state.isLoading
    },
  },
  strict: debug
})

export default store
