import ApiService from '@/core/services/api.service'
import { LOGOUT } from '@/core/stores/modules/auth.module'
import { showSuccessMessage } from '@/core/utils/alerts/sucess';
import router from '@/router';

export const OBTER_DADOS_USUARIO_LOGADO = 'obterDadosUsuarioLogado'
export const ATUALIZAR_DADOS_USUARIO_LOGADO = 'atualizarDadosUsuarioLogado'
export const ATUALIZAR_USERNAME = 'atualizarUsernameUsuarioLogado'
export const ATUALIZAR_PASSWORD = 'atualizarPasswordUsuarioLogado'

export const ATUALIZAR_EMAIL = 'atualizarEmailUsuarioLogado'

export const ATUALIZAR_EMAIL_VERIFY = 'atualizarEmailVerifyUsuarioLogado'

export const ATUALIZAR_AVATAR = 'atualizarAvatar'

const state = {
    errors: null,
    dataUserLogged: {},
    dataUser: {},
    myTourSearch: {
        items: {},
        meta: {}
    },
}

const getters = {
    userLogged (state) {
        return state.dataUserLogged
    },
    dataUser (state) {
        return state.dataUser
    },
    dataMyToursSearch (state) {
        return state.myTourSearch
    }
}

// ações
const actions = {

    [OBTER_DADOS_USUARIO_LOGADO] (context)
    {
        return ApiService.get('/user').then(response =>
        {
            if (response.status === 200)
            {
                context.commit('setUserLogged', response.data)
                return true;
            }
            return false;
        })
        .catch(error => {
            console.error('Erro ao obter dados do usuario logado', error);
        });
    },

    [ATUALIZAR_DADOS_USUARIO_LOGADO] (context, param)
    {
        ApiService.post('/user/profile', param)
            .then(response => {
              if (response.status === 201) {
                showSuccessMessage('Sucesso!', 'Os dados do usuário  foram atualizados.');
                context.dispatch(OBTER_DADOS_USUARIO_LOGADO);
              }
            })
            .catch(error => {
              console.error('Erro ao atualizar os dados do usuário:', error);
            });
    },

    [ATUALIZAR_USERNAME] (context, param)
    {
        ApiService.post('/user/username', param)
        .then(response => {
          if (response.status === 201) {
            showSuccessMessage('Sucesso!', 'O Username foi atualizado.');
            context.dispatch(OBTER_DADOS_USUARIO_LOGADO);
          }
        }).catch(error => {
          console.error('Erro ao atualizar os dados do usuário:', error);
          context.dispatch(OBTER_DADOS_USUARIO_LOGADO);
        });
    },

    [ATUALIZAR_PASSWORD] (context, param) {
    ApiService.post('/user/password/change', param)
        .then(response => {
          if (response.status === 201)
          {
            context.dispatch(LOGOUT);
            showSuccessMessage('Sucesso!', 'A Senha foi atualizada.');
            router.push({ name: 'login' });
          } else {
            // Lidar com outros status de resposta, se necessário
          }
        })
        .catch(error => {
          console.error('Erro ao atualizar os dados do usuário:', error);
          context.dispatch(OBTER_DADOS_USUARIO_LOGADO);
        });
    },

    [ATUALIZAR_AVATAR] (context, param) {
        return ApiService.postForm('/user/image', param)
        .then(response => {
            if (response.status === 201)
            {
                context.dispatch(OBTER_DADOS_USUARIO_LOGADO);
                return true;
            } else {
                // Lidar com outros status de resposta, se necessário
            }
        })
        .catch(error => {
            console.error('Erro ao atualizar os dados de avatar do usuário:', error);
            context.dispatch(OBTER_DADOS_USUARIO_LOGADO);
        });
    },

    [ATUALIZAR_EMAIL] (context, param)
    {
        return ApiService.post('/user/request-email-change', param)
        .then(response => {
            if (response.status === 201) {
                return true
            }
        })
        .catch(error => {
            return false
        });
    },

    [ATUALIZAR_EMAIL_VERIFY] (context, param)
    {
        return ApiService.post('/user/verify-email-change', param)
            .then(response => {
                if (response.status === 201) {
                    return true
                }
            })
            .catch(error => {
                return false
            });
    },

    getUserTour(context, params)
    {
        return ApiService.get('/tour/search/'+params.filter).then(response =>
        {
            if (response.status === 200)
            {
                context.commit('setUserTourSearch', response.data)
                return response.data;
            }

            return false;
        })
        .catch(error => {
            console.error('Erro ao obter os tours do usuario', error);
        });
    },

    getUserById(context, id)
    {
        return ApiService.get('/user/'+ id).then(response =>
        {
            if (response.status === 200)
            {
                context.commit('setCurrentUser', response.data)
                return true;
            }

            return false;

        })
        .catch(error => {
            console.error('Erro ao obter dados do usuario', error);
        });
    },

  updateUserField({ commit }, payload) {
    commit('updateUserField', payload);
  }
}

const mutations = {
    setUserLogged(state, dataSingleResult) {
        state.dataUserLogged = dataSingleResult.data
    },
    setCurrentUser(state, dataSingleResult) {
        state.dataUser = dataSingleResult.data
    },
    updateUserField(state, { field, value }) {
        state.dataUserLogged[field] = value;
    },
    setUserTourSearch(state, dataResults) {
        state.myTourSearch.items = dataResults.data;
        state.myTourSearch.meta = dataResults.meta
    },
}

export default {
  state,
  actions,
  mutations,
  getters
}