import ApiService from '@/core/services/api.service'
import { showSuccessMessage } from '@/core/utils/alerts/sucess';

const state = {
}

const getters = {

}

const actions = {

    deleteItemUserTour(context, idItemUser)
    {
        return ApiService.delete('/item-user/'+idItemUser).then(response =>
        {
            return response.status === 201;
        })
        .catch(error => {
            console.log('Erro ao excluir item do usuário na tour', error);
            return Promise.reject(error);
        });
    },
}

const mutations = {
}

export default {
  state,
  actions,
  mutations,
  getters
}
