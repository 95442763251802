import ApiService from '@/core/services/api.service'
import { showSuccessMessage } from '@/core/utils/alerts/sucess';

const state = {
    items: {},
    item: {},
}

const getters = {
  items (state) {
    return state.items
  }
}

const actions = {

    insertItemTour(context, params)
    {
        return ApiService.post('/item', params).then(response =>
        {
            return response.status === 201;
        })
        .catch(error => {
            console.error('Erro ao atribuir item na tour', error);
            return false
        });
    },

    associateItemUser(context, params)
    {
        return ApiService.post('/item-user', params).then(response =>
        {
            return response.status === 201;
        })
        .catch(error => {
            console.error('Erro ao atribuir item na tour', error);
            return false
        });
    },

    deleteItemTour(context, params)
    {
        return ApiService.delete('/item/'+params.idItem).then(response =>
        {
            if (response.status === 201)
            {
                return context.dispatch('getItemsTour', params.idTour).then(() => {
                    return true;
                }).catch(() => {
                    return false;
                });
            }
            else
            {
                return false;
            }
        })
        .catch(error => {
            console.log('Erro ao excluir item do tour', error);
            return Promise.reject(error);
        });
    },

    changeItemToFinished(context, idItem)
    {
        return ApiService.post('/item/'+idItem+'/finish').then(response =>
        {
            if (response.status === 200) {
                return true;
            }else {
                console.log('Status de retorno foi: '+response.status)
                return false
            }
        })
        .catch(error => {
            console.error('Erro ao alterar o status do item para finalizado', error);
            return Promise.reject(error);
        });
    },

    changeItemToOpen(context, idItem)
    {
        return ApiService.delete('/item/'+idItem+'/finish').then(response =>
        {
            if (response.status === 200) {
                return true;
            }else {
                return false
            }
        })
        .catch(error => {
            console.error('Erro ao alterar o status do item para finalizado', error);
            return Promise.reject(error);
        });
    },

    clearItems(context) {
        context.commit('clearItems')
    },
    clearItem(context) {
        context.commit('clearItem')
    },
}

const mutations = {
    clearItems(state) {
        state.items = {}
    },
    clearItem(state) {
        state.item = {}
    },
}

export default {
  state,
  actions,
  mutations,
  getters
}
