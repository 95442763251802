<template>
  <loading-spinner></loading-spinner>
  <!--<SplashScreen />-->
  <router-view />
</template>

<script>

import LoadingSpinner from './LoadingSpinner.vue';

export default {

  components: {
    //SplashScreen,
    LoadingSpinner
  },

  setup() {},

  methods: {},
}
</script>

<style src="./assets/scss/index.scss" lang="scss" />
